import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import ProductImageModel from '../../../models/ProductImage';
import ImagePreload from '../../common/ImagePreload';
import ConfigStore from '../../../store/ConfigStore';
import TranslationStore from '../../../store/TranslationStore';
import TranslatedImageType from '../../../types/TranslatedImageType';
import ImageMagnifier from '../../image/ImageMagnifier';

const DEFAULT_MIN_RATIO = 5 / 2;
const DEFAULT_PADDING_FOR_WIDE_IMAGES = 100;
const defaultImagePath = '/img/no_image_available.png';

@observer
export class ProductImage extends Component {
  imageElement = null;
  state = {
    fullImageLoaded: false,
    fullImageWidth: 0,
    fullImageHeight: 0,
    imagePadding: false,
  };

  createProductImageModel = (imagePath) => {
    return ProductImageModel.create({
      id: 1,
      product_id: '',
      sizes: {
        small: imagePath,
        medium: imagePath,
        large: imagePath,
        full: imagePath,
      },
      description: '',
    });
  };

  onImagePreload = ({ target: img }) => {
    this.setState({
      fullImageLoaded: true,
      fullImageWidth: img.offsetWidth,
      fullImageHeight: img.offsetHeight,
      imagePadding: this.needsPadding(img),
    });
  };

  getImageAlt = () => {
    const { product, configStore } = this.props;
    const productImage = this.getProductImage();
    const productName = product?.name ?? '';
    const description = configStore.useImageDescriptionAsAlt
      ? productImage.description || productName
      : productName;

    return [
      description,
      product?.mainCategory?.name ?? '',
      product?.id ?? '',
      productImage.id,
    ].join(' - ');
  };

  getProductImage = () => {
    return this.props.productImage || this.getDefaultImage();
  };

  getDefaultImage = () => {
    const { translationStore } = this.props;
    const defaultImage = translationStore.translatedImages.find(
      (image) => image.image_key === TranslatedImageType.IMAGE_NOT_FOUND_NAME
    );
    return defaultImage
      ? this.createProductImageModel(defaultImage.image_path)
      : this.createProductImageModel(defaultImagePath);
  };

  handleImageLoadError = (e) => {
    e.target.onerror = null;
    e.target.src = this.getImageSrc(
      this.createProductImageModel(defaultImagePath)
    );
  };

  getImageSrc = (productImage) => {
    const { size } = this.props;
    return productImage.sizes[size]
      ? productImage.sizes[size]
      : productImage.sizes['full'];
  };

  fullImageLargerThanElement = () => {
    return (
      this.imageElement &&
      (this.imageElement.clientWidth < this.state.fullImageWidth ||
        this.imageElement.clientHeight < this.state.fullImageHeight)
    );
  };

  needsPadding = (img) => {
    const { configStore } = this.props;
    // Padding is added when its width is 2.5 times its height
    if (
      this.imageElement &&
      Number(configStore.product.imageAspectRatio) >= 100
    ) {
      return img.offsetWidth / img.offsetHeight > DEFAULT_MIN_RATIO;
    }
    return false;
  };

  getModifiedAspectRatio = () => {
    const { configStore, overrideConfigStoreAspectRatio, customAspectRatio } =
      this.props;

    if (this.state.fullImageLoaded) {
      const width = this.state.fullImageWidth;
      const height = this.state.fullImageHeight;
      if (width >= height) {
        return DEFAULT_PADDING_FOR_WIDE_IMAGES;
      }
    }

    if (overrideConfigStoreAspectRatio) {
      return customAspectRatio;
    } else {
      return configStore.product.imageAspectRatio;
    }
  };

  render() {
    const {
      magnify,
      forceAspectRatio,
      className,
      wrapperClassName,
      overlayIcon,
      lazyLoading,
      imageStyles,
      draggable,
      onClick,
      onMouseLeave,
      onMouseMove,
      size,
    } = this.props;
    const productImage = this.getProductImage();
    const imageSrc = this.getImageSrc(productImage);
    const alt = this.getImageAlt();

    let imagePreload = '';
    if (magnify) {
      imagePreload = (
        <ImagePreload
          src={productImage.sizes['full']}
          onImageLoad={this.onImagePreload}
        />
      );
    }

    const lazyLoad = lazyLoading ? { loading: 'lazy' } : null;
    let image;
    if (
      magnify &&
      this.state.fullImageLoaded &&
      this.fullImageLargerThanElement()
    ) {
      image = (
        <ImageMagnifier
          className="ProductImage__small-image-container"
          enlargedImageContainerClassName="ProductImage__magnified-image-container"
          smallImage={{
            alt,
            src: imageSrc,
          }}
          largeImage={{
            alt,
            src: productImage.sizes['full'],
            width: this.state.fullImageWidth,
            height: this.state.fullImageHeight,
          }}
        />
      );
    } else if (imageSrc) {
      image = (
        <img
          src={imageSrc}
          alt={alt}
          onError={this.handleImageLoadError}
          style={imageStyles}
          onClick={onClick}
          onMouseLeave={onMouseLeave}
          draggable={draggable}
          onMouseMove={onMouseMove}
          {...lazyLoad}
        />
      );
    } else {
      image = null;
    }
    const style = forceAspectRatio
      ? {
          paddingBottom: this.getModifiedAspectRatio() + '%',
        }
      : null;

    return (
      <div
        className={classNames('ProductImage', className, {
          'ProductImage--force-aspect-ratio': forceAspectRatio,
        })}
        style={style}
        ref={(ref) => (this.imageElement = ref)}
        draggable={false}
      >
        {imagePreload}
        <div
          className={classNames('ProductImage__wrapper', wrapperClassName, {
            'ProductImage__wrapper-wide': this.state.imagePadding,
          })}
        >
          {image}
        </div>
        {overlayIcon}
      </div>
    );
  }
}

ProductImage.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  translationStore: modelOf(TranslationStore).isRequired,
  size: PropTypes.string.isRequired,
  // Allows Product, ChildProduct and OrderProduct.
  product: PropTypes.object,
  productImage: modelOf(ProductImageModel),
  className: PropTypes.string,
  customAspectRatio: PropTypes.string,
  wrapperClassName: PropTypes.string,
  draggable: PropTypes.bool,
  forceAspectRatio: PropTypes.bool,
  lazyLoading: PropTypes.bool,
  magnify: PropTypes.bool,
  overrideConfigStoreAspectRatio: PropTypes.bool,
  imageRef: PropTypes.object,
  imageStyles: PropTypes.object,
  onClick: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseMove: PropTypes.func,
  overlayIcon: PropTypes.node,
};

ProductImage.defaultProps = {
  forceAspectRatio: true,
  lazyLoading: true,
};

export default inject('configStore', 'translationStore')(ProductImage);
