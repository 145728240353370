import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Link, matchPath, withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';
import { Button, Col, Container, Row } from 'reactstrap';

import useNavigationEntityConverter from '../../../hooks/navigation/useNavigationEntityConverter';
import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import RouteService from '../../../services/RouteService';
import AccountStore from '../../../store/AccountStore';
import AdStore from '../../../store/AdStore';
import ConfigStore from '../../../store/ConfigStore';
import CustomNavigationLinkStore from '../../../store/CustomNavigationLinkStore';
import TranslationStore from '../../../store/TranslationStore';
import UIStore from '../../../store/UIStore';
import AdZones from '../../../types/AdZones';
import MobileNavigationTab from '../../../types/MobileNavigationTab';
import NavigationEntityPosition from '../../../types/NavigationEntityPosition';
import Paths from '../../../types/Paths';
import ProductAddedToCardModalType from '../../../types/ProductAddedToCartModalStyle';
import generatePath from '../../../util/generatePath';
import { addParametersToPath } from '../../../util/queryString';
import ContentSlot from '../../ad/ContentSlot';
import MobileNavigationCart from '../../cart/MobileNavigationCart';
import NavigationCart from '../../cart/NavigationCart';
import NavigationCartPopover from '../../cart/NavigationCartPopover';
import NavigationHybridCart from '../../cart/NavigationHybridCart';
import Hamburger from '../../common/Hamburger';
import Icon from '../../common/Icon';
import NavigationEntityItemButton from '../../navigation/NavigationEntityItemButton';
import MobileNavigationProposal from '../../proposal/MobileNavigationProposal';
import NavigationProposal from '../../proposal/NavigationProposal';
import QuickSearch from '../../search/QuickSearch';
import LicensePlateInput from '../../vehicle/LicensePlateInput';
import MobileNavigationButton from '../MobileNavigationButton';

const CommonNavigationMiddle = ({
  accountStore,
  adStore,
  configStore,
  customNavigationLinkStore,
  translationStore,
  uiStore,
  routeService,
  intl,
  history,
  location,
  inputBlur,
  inputFocus,
  onSearchKeyPress,
  onShowSearchOnMobile,
  shouldShowSearchOnMobile,
  navigationLogo,
}) => {
  const moveShoppingCartLoginRight =
    !configStore.topNavigation.moveShoppingCartLoginRight;

  const renderNavigationEntityItemButton = (navigationLinks) => {
    const navigationEntityItems = useNavigationEntityConverter({
      entities: navigationLinks,
    });

    return navigationEntityItems.map((entity) => (
      <div key={entity.id} className="NavigationEntityButton__button-wrapper">
        <NavigationEntityItemButton
          entity={entity}
          buttonContentClassName={'NavigationEntityButton__button-content'}
        />
      </div>
    ));
  };

  const renderNavigationHeader = () => {
    const showCart = !accountStore.isViewOnly;
    const centerShopLogo = configStore.topNavigation.centerShopLogo;

    return (
      <>
        {navigationLogo}
        {!configStore.siteConfig.isHomePage && !centerShopLogo && (
          <div
            className={classNames('CommonNavigation__middle-right-content', {
              'CommonNavigation__middle-only-content': !showCart,
              'CommonNavigation__middle-center-content':
                configStore.topNavigation.center,
            })}
          >
            <div className="CommonNavigation__middle-right-content-top">
              {!configStore.topNavigation.moveSearchbarTopNavigation && (
                <>
                  <div className="CommonNavigation__middle-right-ghost" />
                  <QuickSearch
                    handleFocus={inputFocus}
                    handleBlur={inputBlur}
                    toggleSearch={onSearchKeyPress}
                  />
                </>
              )}
              {getNavigationEntityButtons()}
              {showCart &&
                moveShoppingCartLoginRight &&
                configStore.siteConfig.isWebStore && (
                  <div className="CommonNavigation__middle-right-cart-wrapper">
                    {getNavigationCart()}
                  </div>
                )}
            </div>
          </div>
        )}
      </>
    );
  };

  const renderVehicleSearchPartHeader = () => {
    const showCart = !accountStore.isViewOnly;
    const centerShopLogo = configStore.topNavigation.centerShopLogo;

    return !centerShopLogo ? (
      <Row className="CommonNavigation__middle-content-row">
        <Col
          md={centerShopLogo ? 8 : 3}
          xl={centerShopLogo ? 8 : 4}
          className="pl-0"
        >
          {centerShopLogo && <div />}
          {navigationLogo}
        </Col>
        <Col
          md={9}
          xl={8}
          className={classNames('CommonNavigation__middle-right-content', {
            'CommonNavigation__middle-only-content': !showCart,
            'CommonNavigation__middle-center-content':
              configStore.topNavigation.center,
          })}
        >
          <div className="CommonNavigation__license-plate-input">
            {!configStore.topNavigation.moveSearchbarTopNavigation && (
              <LicensePlateInput
                handleOnSubmit={handleVehicleSearch}
                clearAfterSubmit
              />
            )}
          </div>
          <div className="CommonNavigation__middle-right-content-top">
            {!configStore.topNavigation.moveSearchbarTopNavigation && (
              <QuickSearch
                handleFocus={inputFocus}
                handleBlur={inputBlur}
                toggleSearch={onSearchKeyPress}
              />
            )}
            {getNavigationEntityButtons()}
            {showCart &&
              moveShoppingCartLoginRight &&
              configStore.siteConfig.isWebStore && (
                <div className="CommonNavigation__middle-right-cart-wrapper">
                  <NavigationCart
                    className={'NavigationCart__vehicle-search-part'}
                    wrapperClassName={
                      'NavigationCart__vehicle-search-part-cart-button-wrapper'
                    }
                  />
                  {cartIsPopover() && <NavigationCartPopover />}
                </div>
              )}
          </div>
        </Col>
      </Row>
    ) : (
      renderNavigationHeader()
    );
  };

  const desktopCommonNavigation = () => {
    const centerShopLogo = configStore.topNavigation.centerShopLogo;
    const shouldShowBanner =
      configStore.topNavigation.moveSearchbarTopNavigation;
    const vehiclePartSearchEnabled = configStore.vehiclePartSearch.enabled;
    return (
      <div
        className={classNames(
          'CommonNavigationMiddle',
          'CommonNavigation__middle-content',
          {
            'CommonNavigation__middle-content--centered-logo': centerShopLogo,
          }
        )}
      >
        {vehiclePartSearchEnabled
          ? renderVehicleSearchPartHeader()
          : renderNavigationHeader()}
        {shouldShowBanner && getBanners()}
      </div>
    );
  };

  const getNavigationEntityButtons = () => {
    const navigationLinks = customNavigationLinkStore.getNavigationEntities();

    if (!navigationLinks.length) {
      return;
    }

    return renderNavigationEntityItemButton(navigationLinks);
  };

  const getNavigationEntityButtonsByPosition = (position) => {
    const navigationLinks =
      customNavigationLinkStore.getNavigationEntitiesByPosition(position);

    if (!navigationLinks.length) {
      return;
    }

    return renderNavigationEntityItemButton(navigationLinks);
  };

  const getBanners = () => {
    const ads = adStore.getGlobalAdsByAdZone(AdZones.HEADER_CONTENT);

    if (!ads || ads.length === 0) {
      return null;
    }

    return (
      <div className="CommonNavigation__content-slots CommonNavigation__content-slots__container">
        {ads.map((ad) => (
          <ContentSlot
            ad={ad}
            bannerZone={AdZones.HEADER_CONTENT}
            key={ad.id}
          />
        ))}
      </div>
    );
  };

  const cartIsPopover = () => {
    if (!accountStore.account) {
      return null;
    }

    const isRetailer = accountStore.account.is_retailer;
    const modalType = configStore.cart.type;

    return isRetailer || modalType === ProductAddedToCardModalType.POPOVER;
  };

  const getNavigationCart = () => {
    const doNotRenderCart = accountStore.isViewOnly;

    if (doNotRenderCart) {
      return null;
    }

    if (configStore.siteConfig.isHybrid) {
      return (
        <NavigationHybridCart
          navigationCart={<NavigationCart isText={true} />}
          navigationProposal={<NavigationProposal isText={true} />}
        />
      );
    }

    const shouldUseSimple =
      uiStore.isMobile && configStore.cart.button.simplifiedMobile;
    const navigationCart = shouldUseSimple ? (
      <MobileNavigationCart />
    ) : (
      <NavigationCart />
    );

    const navigationProposal = shouldUseSimple ? (
      <MobileNavigationProposal />
    ) : (
      <NavigationProposal />
    );

    return (
      <div className="NavigationCart__wrapper">
        {!configStore.siteConfig.proposalEnabled && navigationCart}
        {configStore.siteConfig.proposalEnabled && navigationProposal}
        {cartIsPopover() && <NavigationCartPopover />}
      </div>
    );
  };

  const mobileCommonNavigation = () => {
    const vehiclePartSearchEnabled = configStore.vehiclePartSearch.enabled;
    const mobileSearchbarVisibility =
      configStore.topNavigation.headerMobileSearchbarVisibility;
    const visibleMobileSearchbar =
      shouldShowSearchOnMobile || mobileSearchbarVisibility;
    const hideMobileContainer = uiStore.searchIsActive;
    const mobileLogo = translationStore.mobileLogo;

    return (
      <div
        className={classNames(
          'CommonNavigationMiddle',
          'CommonNavigation__middle-content'
        )}
      >
        <Container className="CommonNavigation__middle-content-container">
          {!hideMobileContainer && (
            <Row
              className={classNames('CommonNavigation__mobile-container-row', {
                'CommonNavigation__mobile-container-row--hidden':
                  hideMobileContainer,
              })}
            >
              <Col xs={12} className="CommonNavigation__mobile-container">
                <MobileNavigationButton
                  tab={getDefaultMobileMenuTab()}
                  forceClose={uiStore.mobileNavigation.isOpen}
                  ariaLabel={intl.formatMessage(
                    globalTranslations.seeCategoriesNavi
                  )}
                >
                  <Hamburger active={uiStore.mobileNavigation.isOpen} />
                </MobileNavigationButton>
                <div className="CommonNavigation__mobile-home">
                  {getNavigationEntityButtonsByPosition(
                    NavigationEntityPosition.LEFT
                  )}
                  <Link to="/">
                    <Button
                      color="plain"
                      className="CommonNavigation__icons"
                      aria-label={intl.formatMessage(
                        globalTranslations.homeTitle
                      )}
                    >
                      {mobileLogo.length > 0 ? (
                        <img
                          className="CommonNavigation__mobile-logo-img"
                          alt={configStore.store.name}
                          src={mobileLogo[0].image_path}
                        />
                      ) : (
                        <Icon name="home" className="fa-2x" />
                      )}
                    </Button>
                  </Link>
                  {getNavigationEntityButtonsByPosition(
                    NavigationEntityPosition.RIGHT
                  )}
                </div>
                <div className="CommonNavigation__mobile-right">
                  {!configStore.siteConfig.isHomePage &&
                    !mobileSearchbarVisibility && (
                      <Button
                        color="plain"
                        className="CommonNavigation__icons"
                        onClick={onShowSearchOnMobile}
                        aria-label={intl.formatMessage(
                          globalTranslations.search
                        )}
                      >
                        <Icon name="search" className="fa-2x" />
                      </Button>
                    )}
                  {getMobileIconForAccountIfWebStore()}
                  {getMobileIconForWishListIfShoppingCentreAndEnabled()}
                  {configStore.storeInfoPageId > 0 && (
                    <Button
                      color="plain"
                      className="CommonNavigation__icons"
                      onClick={navigateToStoreInfoPage}
                      aria-label={intl.formatMessage(
                        globalTranslations.infoTitle
                      )}
                    >
                      <Icon name="map-marker" className="fa-2x" />
                    </Button>
                  )}
                  {getNavigationCart()}
                </div>
              </Col>
            </Row>
          )}
          {visibleMobileSearchbar && (
            <Row
              className={classNames('CommonNavigation__mobile-search-row', {
                'CommonNavigation__mobile-search-row--no-padding':
                  hideMobileContainer,
                'CommonNavigation__mobile-search-row--show':
                  visibleMobileSearchbar,
              })}
            >
              {vehiclePartSearchEnabled && (
                <Col sm={{ size: 6, offset: 6 }} className="mb-2">
                  <LicensePlateInput
                    handleOnSubmit={handleVehicleSearch}
                    clearAfterSubmit
                  />
                </Col>
              )}
              <Col sm={12}>
                <QuickSearch
                  handleFocus={inputFocus}
                  handleBlur={inputBlur}
                  toggleSearch={onSearchKeyPress}
                />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    );
  };

  const getDefaultMobileMenuTab = () => {
    if (
      matchPath(location.pathname, {
        path: '/:locale?' + Paths.InfoPage,
      })
    ) {
      return MobileNavigationTab.INFO;
    }

    return MobileNavigationTab.PRODUCTS;
  };

  const getMobileIconForAccountIfWebStore = () => {
    return (
      configStore.siteConfig.isWebStore && (
        <MobileNavigationButton
          tab={MobileNavigationTab.ACCOUNT}
          className="ml-auto text-uppercase CommonNavigation__icons"
          ariaLabel={intl.formatMessage(globalTranslations.myAccountTitle)}
        >
          <Icon name="user" className="fa-2x" />
        </MobileNavigationButton>
      )
    );
  };

  const getMobileIconForWishListIfShoppingCentreAndEnabled = () => {
    return (
      configStore.wishlist.enabled &&
      configStore.siteConfig.isShoppingCenter && (
        <MobileNavigationButton
          tab={MobileNavigationTab.ACCOUNT}
          className="ml-auto text-uppercase"
          ariaLabel={intl.formatMessage(globalTranslations.seeCategoriesNavi)}
        >
          <Icon name="star" className="fa-2x" />
        </MobileNavigationButton>
      )
    );
  };

  const navigateToStoreInfoPage = () => {
    const storeInfoPagePath = generatePath(Paths.InfoPage, {
      slug: '-',
      id: configStore.storeInfoPageId,
    });
    history.push(routeService.getPath(storeInfoPagePath));
  };

  const handleVehicleSearch = (licensePlate) => {
    const licensePlateUrl = licensePlate
      ? addParametersToPath(Paths.VehiclePartSearch, {
          id: licensePlate,
        })
      : Paths.VehiclePartSearch;
    const url = routeService.getPath(licensePlateUrl);

    history.push(url);
  };

  return uiStore.isDesktop
    ? desktopCommonNavigation()
    : mobileCommonNavigation();
};

CommonNavigationMiddle.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  adStore: modelOf(AdStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  customNavigationLinkStore: modelOf(CustomNavigationLinkStore).isRequired,
  translationStore: modelOf(TranslationStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  intl: intlShape.isRequired,
  history: RouterPropTypes.history.isRequired,
  location: RouterPropTypes.location.isRequired,
  shouldShowSearchOnMobile: PropTypes.bool.isRequired,
  navigationLogo: PropTypes.object.isRequired,
  inputBlur: PropTypes.func.isRequired,
  inputFocus: PropTypes.func.isRequired,
  onSearchKeyPress: PropTypes.func.isRequired,
  onShowSearchOnMobile: PropTypes.func.isRequired,
};

export default inject(
  'accountStore',
  'adStore',
  'configStore',
  'customNavigationLinkStore',
  'translationStore',
  'uiStore',
  'routeService'
)(withRouter(injectIntl(observer(CommonNavigationMiddle))));
// observer HOC needs to be used as innermost until we have implemented MST as a hook.
//https://mobx.js.org/react-integration.html#wrap-order
